//@flow

import * as React from 'react';
import {
    Box,
    TextareaAutosize,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ClientContext from './ClientContext';
import { history } from '../index';
import queryString from 'query-string';
import HelplineText from './HelplineText';
import useStyles from './LandingPageStyles';
import RoutesEnum from './RoutesEnum';

export const getIncorrectTrackingId = (ids,maxCount) => {
    let inCorrectIds = [];
    if(!ids || !Array.isArray(ids))
        return { inCorrectIds , errorMessageKey: null, errorMessageParams: null};
    const orderLength = ids.length;

    const hasIncorrectTrackingIds = () => {
        inCorrectIds = ids.filter(item => (!/^\d+$/.test(item) || item.length !== 12));
        if(inCorrectIds.length > 0) return true;
        else return false;
    }

    if ((orderLength === 1 && ids[0] === '') || orderLength === 0)
        return { inCorrectIds, errorMessageKey: "trackingIdRequired", errorMessageParams: null};
    else if(hasIncorrectTrackingIds())
        return { inCorrectIds, errorMessageKey: "trackingIdInCorrect", errorMessageParams: {orders: inCorrectIds}};
    else if(orderLength > maxCount)
        return { inCorrectIds, errorMessageKey: "trackingIdExceed", errorMessageParams: {count: maxCount}};

    return { inCorrectIds , errorMessageKey: null, errorMessageParams: null};
}

const LandingPage = () => {
    const {
        clientTheme,
    } = React.useContext(ClientContext);

    const { trackingId } = queryString.parse(location.search);
    const [searchIds, setSearchIds] = React.useState(trackingId || "");
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorMessageParams, setErrorMessageParams] = React.useState(null);
    const classes = useStyles(clientTheme,errorMessage);
    const { t } = useTranslation();

    const StyledButton = withStyles({
        root: {
            color: 'white',
            backgroundColor: clientTheme.button.color,
            borderRadius: 0,
            fontSize: clientTheme.button.fontSize,
            fontWeight: clientTheme.button.fontWeight,
            width: "100%",
            maxWidth: clientTheme.button.width,
            height: clientTheme.button.height,
            '&:hover': {
                backgroundColor: clientTheme.button.color,
            },
        },
    })((props) => <Button {...props} />);

    const handleSearchIdChange = (e) => {
        if(errorMessage !== null){
            setErrorMessageParams(null);
            setErrorMessage(null)
        }
        setSearchIds(e.target.value);
    };

    React.useEffect(() => {
        setTrackIdInUrl();
    }, [searchIds])

    const fetchOrders = () => {
        const splitOrder = searchIds
            .split(',')
            .map((splitOrder) => splitOrder.trim())
            .filter((splitOrder, index, ref) => splitOrder && ref.indexOf(splitOrder) === index);

        const {errorMessageKey, errorMessageParams} = getIncorrectTrackingId(splitOrder,5);
        if(errorMessageKey){
            setErrorMessageParams(errorMessageParams);
            return setErrorMessage(errorMessageKey);
        }else{
            setErrorMessageParams(null);
            setErrorMessage(null)
        }

        history.push({
            pathname: RoutesEnum.Summary,
            search: queryString.stringify({
                "trackingId": splitOrder.join(','),
            }),
        });
    };

    const setTrackIdInUrl = () => {
        history.push({
            pathname: RoutesEnum.LandingPage,
            search: queryString.stringify({
                "trackingId": searchIds
            }),
        });
    };

    return (
        <>
            <Box className={classes.wrapper}>
                <Box borderBottom={`1px solid ${clientTheme.borderTheme[100]}`} pb="16px">
                    <Box className={classes.title} pb="8px">
                        {t('trackYourOrderTitle')}
                    </Box>
                    <Box className={classes.subtitle}>
                        {t('trackYourOrderSubtitle')}
                    </Box>
                </Box>
                <Box py="16px">
                    <Box className={classes.formTitle} pb="8px">
                        {t('trackingId')}
                    </Box>
                    <TextareaAutosize
                        minRows={3}
                        className={classes.enterId}
                        value={searchIds}
                        onChange={handleSearchIdChange}
                    />
                    <Box className={errorMessage ? classes.errorMessage : classes.placeHolder}>
                        {errorMessage 
                            ? t(errorMessage, errorMessageParams) 
                            : t('orderIdPlaceholder', { orderType : t('trackingId')})}
                    </Box>
                </Box>
                <Box>
                    <StyledButton variant="contained" onClick={fetchOrders}>
                        {t('trackOrders')}
                    </StyledButton >
                </Box>
            </Box>
            <Box pb="16px">
                <HelplineText />
            </Box>
        </>
    );
};

export default LandingPage;
