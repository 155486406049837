//@flow

import * as React from 'react';
import { Route, Switch, type Match, Router } from 'react-router-dom';
import AppContainer from './AppContainer';
import RoutesEnum from './RoutesEnum';

type Props = {
    match: Match,
};

const AppRoutes = (props: Props): React.Node => {
    const { history } = props;
    return (
        <Router history={history}>
            <Switch>
                <Route path={RoutesEnum.LandingPage} component={AppContainer} />
            </Switch>
        </Router>
    );
};

export default AppRoutes;
