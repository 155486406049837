import { makeStyles } from '@material-ui/core/styles';

const useStyles = (clientTheme) => {
    const styles = makeStyles((theme) => {
        return {
            wrapper: {
                padding: '32px',
                border: `1px solid ${clientTheme.borderTheme[300]}`,
                backgroundColor: 'white',
                borderRadius: '4px',
                marginBottom: "12px",
            },
            wrapperXs: {
                padding: '16px',
                margin: '16px 0',
                border: `1px solid ${clientTheme.borderTheme[300]}`,
                backgroundColor: 'white',
                borderRadius: '4px',
            },
            backButton:{
                cursor: 'pointer'
            },
            title: {
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '25px',
                marginLeft: '12px',
            },
            titleXs: {
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                marginLeft: '6px',
            },
            orderTitle:{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '25px',
            },
            orderTitleXs:{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px',
            },
            tableHeader: {
                fontWeight: 600,
                fontSize: '16px',
                color: '#111318',
                padding: '8px',
            },
            tableHeaderXs:{
                fontSize: '14px',
                color: '#111318',
                paddingBottom: '4px',
            },
            tableCell: {
                fontSize: '14px',
                color: '#111318',
                padding: '8px',
            },
            tableCellxs: {
                fontSize: '12px',
                color: '#111318',
            },
            tableRow: {
                boxShadow: '0px 0px 4px 0px #090A0C29',
                marginTop: '14px',
                marginBottom: '14px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '4px',
            },
            viewDetails: {
                fontSize: '15px',
                fontWeight: 300,
                color: "#005DB5",
            },
            description: {
                color: '#8992A9',
            },
            orderStatusDetail:{
                fontSize: '14px',
                color: '#111318',
                paddingBottom: '4px',
            },
            orderStatus: {
                fontSize: '12px',
                textAlign: 'center'
            },
            lastUpdated: {
                fontSize: '12px',
                color: '#8992A9',
            },
            notFound: {
                backgroundColor: '#FBDCDA',
                fontSize: '14px',
                fontWeight: 600,
                borderRadius: '4px',
                marginTop: "12px",
            },
            [theme.breakpoints.down('xs')]: {
                backButtonIcon:{
                    width: '18px'
                },
            },
            [theme.breakpoints.up('sm')]: {
                
            }
        }
    });
    return styles();
};

export default useStyles;
