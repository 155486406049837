export default {
    100: '#F3F4F6',
    101: '#FFF1D6',
    102: '#FFF1D6',
    103: '#D6E9FF',
    104: '#D6E9FF',
    105: '#D6E9FF',
    106: '#D6E9FF',
    107: '#FBDCDA',
    108: '#DEF8DE', 
    201: "#FBDCDA",
    202: "#FBDCDA",
    203: "#FBDCDA",
    204: "#FBDCDA",
    205: "#FBDCDA",
    206: "#FBDCDA",
    207: "#FBDCDA",
    208: "#FBDCDA",
    209: "#FBDCDA",
    500: "FFFFFF"
};
