//@flow

import * as React from 'react';
import {
    Route,
    Switch,
    withRouter,
    Redirect,
} from 'react-router-dom';

import LandingPage from './LandingPage';
import OrderDetails from './OrderDetails';
import OrderSummary from './OrderSummary';
import RoutesEnum from './RoutesEnum';

const AppRoutes = (): React.Node => {
    return (
        <Switch>
            <Route
                exact
                path={RoutesEnum.LandingPage}
                component={LandingPage}
            />
            <Route path={RoutesEnum.Details} component={OrderDetails} />
            <Route path={RoutesEnum.Summary} component={OrderSummary} />
            <Redirect to={RoutesEnum.LandingPage} />
        </Switch>
    );
};

export default withRouter(AppRoutes);
