//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, TitleContainer } from './Utils';
import { useTranslation } from 'react-i18next';

const ServiceUnavailable = () => {
    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                body: {
                    fontWeight: 400,
                    letterSpacing: '0px',
                    textAlign: 'left',
                },
                title: {
                    fontWeight: '400',
                    letterSpacing: '0px',
                    textAlign: 'left',
                },
                [theme.breakpoints.down('xs')]: {
                    body: {
                        fontSize: '14px',
                        lineHeight: '21px',
                    },
                    title: {
                        fontSize: '14px',
                        lineHeight: '21px',
                    },
                },
                [theme.breakpoints.down('md')]: {
                    body: {
                        fontSize: '14px',
                        lineHeight: '21px',
                    },
                    title: {
                        fontSize: '14px',
                        lineHeight: '21px',
                    },
                },
                [theme.breakpoints.up('md')]: {
                    body: {
                        fontSize: '15px',
                        lineHeight: '23px',
                    },
                    title: {
                        fontSize: '15px',
                        lineHeight: '23px',
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box>
            <TitleContainer
                title={t('serviceTemporaryClosed')}
                dividerProps={{ mt: '16px' }}
            />
            <Divider />
            <Box pt="24px">
                <Box className={classes.body}>
                    {t('serviceTemporaryClosedMsg')}
                </Box>
            </Box>
            {/* <Box pt="8px">
                <Box className={classes.body} display="flex">
                    <Box pr="6px">
                        If you require assistance, please contact customer
                        service at{' '}
                    </Box>
                    <LinkText>wecare@uniqlo.ca</LinkText>
                    <Box>.</Box>
                </Box>
            </Box>
            <Box pt="24px">
                <Box className={classes.title}>UNIQLO</Box>
            </Box>
            <Box pt="16px">
                <LinkText>FAQ</LinkText>
            </Box>
            <Box pt="16px">
                <LinkText>Contact us</LinkText>
            </Box>
            <Box pt="24px">
                <Box className={classes.title}>GU</Box>
            </Box>
            <Box pt="16px">
                <LinkText>FAQ</LinkText>
            </Box>
            <Box pt="16px">
                <LinkText>Contact us</LinkText>
            </Box> */}
        </Box>
    );
};

export default ServiceUnavailable;
