//@flow

import * as React from 'react';
import { Box, Button, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ClientContext from './ClientContext';
import { history } from '../index';
import { useTrackTask } from './hooks/useOrderTrack';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import StatusColorEnum from './StatusColorEnum';
import { formatSlotTime, formatTime, getHumanizedDiff } from './Utils';
import HelplineText from './HelplineText';
import Loader from './Loader';
import RoutesEnum from './RoutesEnum';
import { getIncorrectTrackingId } from './LandingPage';
import useStyles from './OrderSummaryStyles';
import ServiceUnavailable from './ServiceUnavailable';

const OrderSummary = () => {
    const { t } = useTranslation();
    const { clientId, clientTheme } = React.useContext(ClientContext);
    const location = useLocation();
    const { trackingId } = queryString.parse(location.search);

    const trackingIds = trackingId
        .split(',')
        .map((splitOrder) => splitOrder.trim())
        .filter((splitOrder, index, ref) => {
            return splitOrder && ref.indexOf(splitOrder) === index;
        });

    const {
        inCorrectIds,
        errorMessageKey,
        errorMessageParams,
    } = getIncorrectTrackingId(trackingIds, 5);

    let trackOrderQuery = null;
    if (!errorMessageKey) {
        trackOrderQuery = useTrackTask({
            clientId,
            searchIds: trackingIds
                .filter((id) => inCorrectIds.indexOf(id) < 0)
                .join(','),
        });
    }

    const classes = useStyles(clientTheme);

    const StyledButton = withStyles({
        root: {
            color: clientTheme.button.color,
            borderRadius: 0,
            border: `1px solid ${clientTheme.button.color}`,
            backgroundColor: 'white',
            fontSize: clientTheme.button.fontSize,
            fontWeight: clientTheme.button.fontWeight,
            width: '100%',
            maxWidth: clientTheme.button.width,
            height: clientTheme.button.height,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
    })((props) => <Button {...props} />);

    const trackNewOrderButton = (
        <StyledButton
            variant="outlined"
            onClick={() => history.push(RoutesEnum.LandingPage)}
        >
            {t('trackNewOrders')}
        </StyledButton>
    );

    if (trackOrderQuery?.isFetching) {
        return <Loader />;
    }

    if (
        trackOrderQuery?.isError &&
        trackOrderQuery?.error?.message === 'Network Error'
    ) {
        return <ServiceUnavailable />;
    }

    let ordersById = {};
    if (trackOrderQuery?.data && Array.isArray(trackOrderQuery.data)) {
        ordersById = trackOrderQuery.data.reduce((objById, item) => {
            const orderId = item.sourceOrderId.split('_')[0];
            objById[orderId] = objById[orderId]
                ? [...objById[orderId], item]
                : [item];
            return objById;
        }, {});
    }

    let missingOrders = [];
    if (!errorMessageKey) {
        if (inCorrectIds.length > 0) {
            missingOrders = trackingIds.filter(
                (orderId) =>
                    Object.keys(ordersById).indexOf(orderId) < 0 &&
                    inCorrectIds.indexOf(orderId) < 0,
            );
        } else {
            missingOrders = trackingIds.filter(
                (orderId) => Object.keys(ordersById).indexOf(orderId) < 0,
            );
        }
    }

    if (missingOrders.length > 0) {
        missingOrders.forEach((orderId) => {
            ordersById[orderId] = [];
            ordersById[orderId].push({
                clientId: null,
                etd: null,
                history: [
                    {
                        statusUpdates: [
                            {
                                orderStatus: 'ACCEPTED',
                                orderStatusCode: 100,
                                orderSubStatus: null,
                                orderSubStatusCode: null,
                                timeStamp: null,
                            },
                        ],
                    },
                ],
                id: orderId,
                orderStatus: 'ACCEPTED',
                orderStatusCode: 100,
                orderSubStatus: null,
                orderSubStatusCode: null,
                slotTime: { start: null, end: null },
                sourceOrderId: orderId,
                timeStamp: null,
            });
        });
    }

    return (
        <>
            <Hidden only="xs">
                <Box className={classes.wrapper}>
                    <Box
                        borderBottom={`1px solid ${clientTheme.borderTheme[100]}`}
                        pb="16px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box display="flex">
                            <Box
                                className={classes.backButton}
                                display="flex"
                                alignItems="center"
                                onClick={() =>
                                    history.push({
                                        pathname: RoutesEnum.LandingPage,
                                        search: queryString.stringify({
                                            trackingId,
                                        }),
                                    })
                                }
                            >
                                <img src="/static/chevron-left-small.svg" />
                            </Box>
                            <Box
                                className={classes.title}
                                display="flex"
                                alignItems="center"
                            >
                                {t('yourOrders')}
                            </Box>
                        </Box>
                        {trackNewOrderButton}
                    </Box>
                    {errorMessageKey && (
                        <Box
                            alignItems="center"
                            display="flex"
                            className={classes.notFound}
                            p="10px"
                            my="16px"
                        >
                            <img src="/static/error.svg" />
                            <Box ml="8px">
                                {t(errorMessageKey, errorMessageParams)}
                            </Box>
                        </Box>
                    )}
                    {Object.keys(ordersById).length > 0 && (
                        <Box>
                            <Box display="flex" px="24px" my="16px">
                                <Box
                                    className={classes.tableHeader}
                                    width="20%"
                                >
                                    {t('trackingId')}
                                </Box>
                                <Box
                                    className={classes.tableHeader}
                                    width="40%"
                                >
                                    {t('shipmentStatus')}
                                </Box>
                                <Box
                                    className={classes.tableHeader}
                                    width="40%"
                                >
                                    {t('estimatedDelivery')}
                                </Box>
                            </Box>
                            {Object.keys(ordersById).map((orderId) => {
                                const order = ordersById[orderId][0];
                                const {
                                    orderStatusCode,
                                    orderSubStatusCode,
                                    slotTime,
                                    timeStamp,
                                } = order;
                                return (
                                    <Box
                                        key={orderId}
                                        px="24px"
                                        className={classes.tableRow}
                                        onClick={() =>
                                            history.push({
                                                pathname: RoutesEnum.Details,
                                                search: queryString.stringify({
                                                    trackingId: orderId,
                                                }),
                                            })
                                        }
                                    >
                                        <Box
                                            className={classes.tableCell}
                                            width="20%"
                                        >
                                            {orderId}
                                        </Box>
                                        <Box
                                            className={classes.tableCell}
                                            display="flex"
                                            alignItems="center"
                                            width="40%"
                                        >
                                            <Box
                                                m="8px 8px 8px 0"
                                                p="4px 6px"
                                                className={classes.orderStatus}
                                                borderRadius="2px"
                                                style={{
                                                    backgroundColor:
                                                        StatusColorEnum[
                                                            orderStatusCode
                                                        ],
                                                }}
                                            >
                                                {t(
                                                    `orderStatus.${
                                                        orderStatusCode === 107
                                                            ? orderSubStatusCode
                                                            : orderStatusCode
                                                    }`,
                                                )}
                                            </Box>
                                            <Box
                                                className={classes.lastUpdated}
                                                title={formatTime(timeStamp)}
                                            >
                                                {timeStamp &&
                                                    t('updatedAgo', {
                                                        duration: getHumanizedDiff(
                                                            timeStamp,
                                                        ),
                                                    })}
                                            </Box>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            className={classes.tableCell}
                                            width="40%"
                                        >
                                            {slotTime
                                                ? formatSlotTime(
                                                      slotTime.start,
                                                      slotTime.end,
                                                  ) || '-'
                                                : '-'}
                                            <img src="/static/arrow-right.svg" />
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Hidden>
            <Hidden smUp>
                <Box display="flex">
                    <Box
                        className={classes.backButtonXs}
                        display="flex"
                        alignItems="center"
                        onClick={() =>
                            history.push({
                                pathname: RoutesEnum.LandingPage,
                                search: queryString.stringify({ trackingId }),
                            })
                        }
                    >
                        <img
                            src="/static/chevron-left-small.svg"
                            width="18px"
                        />
                    </Box>
                    <Box className={classes.titleXs}>{t('yourOrders')}</Box>
                </Box>
                {errorMessageKey && (
                    <Box
                        alignItems="center"
                        display="flex"
                        className={classes.notFound}
                        p="10px"
                    >
                        <img src="/static/error.svg" />
                        <Box ml="8px">
                            {t(errorMessageKey, errorMessageParams)}
                        </Box>
                    </Box>
                )}
                {Object.keys(ordersById).map((orderId) => {
                    const order = ordersById[orderId][0];
                    const {
                        orderStatusCode,
                        orderSubStatusCode,
                        slotTime,
                    } = order;

                    return (
                        <Box key={orderId} className={classes.wrapperXs}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                pb="6px"
                            >
                                <Box
                                    className={classes.orderTitleXs}
                                    display="flex"
                                >
                                    <Box>{t(`trackingId`)}</Box>
                                    <Box ml="5px">{orderId}</Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className={classes.orderStatusDetail}>
                                    {t(`shipmentStatus`)}:
                                </Box>
                                <Box display="flex">
                                    <Box
                                        p="4px 6px"
                                        className={classes.orderStatus}
                                        borderRadius="2px"
                                        style={{
                                            backgroundColor:
                                                StatusColorEnum[
                                                    orderStatusCode
                                                ],
                                        }}
                                    >
                                        {t(
                                            `orderStatus.${
                                                orderStatusCode === 107
                                                    ? orderSubStatusCode
                                                    : orderStatusCode
                                            }`,
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Box py="8px">
                                <Box
                                    justifyContent="space-between"
                                    className={classes.tableCellxs}
                                >
                                    <Box className={classes.tableHeaderXs}>
                                        {t('estimatedDelivery')}:
                                    </Box>
                                    <Box>
                                        {slotTime
                                            ? formatSlotTime(
                                                  slotTime.start,
                                                  slotTime.end,
                                              ) || '-'
                                            : '-'}
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    className={classes.viewDetails}
                                    onClick={() =>
                                        history.push({
                                            pathname: RoutesEnum.Details,
                                            search: queryString.stringify({
                                                trackingId: orderId,
                                            }),
                                        })
                                    }
                                >
                                    {t('viewDetails')}
                                    <img
                                        src="/static/chevron_right.svg"
                                        style={{ padding: '4px' }}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    );
                })}
                <Box my="16px" display="flex" justifyContent="center">
                    {trackNewOrderButton}
                </Box>
            </Hidden>
            <Box pb="16px">
                <HelplineText />
            </Box>
        </>
    );
};

export default OrderSummary;
