//@flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import ClientContext from './ClientContext';
import useStyles from './AppContainerStyles';

import AppHeader from './AppHeader';
import AppRoutes from './AppRoutes';
import AppBreadcrumbs from './AppBreadcrumbs';

const AppContainer = (props): React.Node => {
    const { clientTheme } = React.useContext(ClientContext);
    const classes = useStyles(clientTheme);
    const { t } = useTranslation();

    const getCopyRights = () => {
        if (Array.isArray(clientTheme.brand.list)) {
            return (
                <Box>
                    {clientTheme.brand.list.map((name, index) => (
                        <Box
                            key={index}
                            className={
                                classes[`copyRights${index > 0 ? 'x' : '1'}`]
                            }
                        >
                            {clientTheme.brand[`${name}copyRights`]}
                        </Box>
                    ))}
                </Box>
            );
        } else return <Box> {clientTheme.brand.copyRights} </Box>;
    };

    return (
        <Box className={classes.bodyWrap}>
            <Box
                className={classes.headerWrap}
                display="flex"
                alignItems="center"
            >
                <Box width="100%" className={classes.header}>
                    <AppHeader />
                </Box>
            </Box>
            <Box>
                {clientTheme?.showTicker && (
                    <Box className={classes.ticker}>
                        {t('serviceUnavailableTickerMsg')}
                    </Box>
                )}
            </Box>
            <Box className={classes.navigationWrap}>
                <Box
                    display="flex"
                    alignItems="center"
                    height="100%"
                    className={classes.navigation}
                >
                    <AppBreadcrumbs />
                </Box>
            </Box>
            <Box className={classes.trackerWrapper}>
                <Box className={classes.tracker}>
                    <AppRoutes {...props} />
                </Box>
            </Box>
            <Box className={classes.bottomBarWrap}>
                <Box
                    className={classes.bottomBar}
                    display="flex"
                    alignItems="center"
                >
                    {getCopyRights()}
                </Box>
            </Box>
        </Box>
    );
};

export default AppContainer;
