// @flow
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
    },
    typography: {
        fontFamily:
            '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Noto Sans CJK JP", Osaka, Meiryo, メイリオ, "MS PGothic", "ＭＳ Ｐゴシック", YuGothic, "Yu Gothic", "Hiragino Sans GB", Helvetica Neue, HelveticaNeue , Helvetica, Noto Sans, Roboto,Arial, "Arial Unicode MS", sans-serif;',
        h1: {
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            fontWeight: 500,
            letterSpacing: '0.05rem',
        },
        h2: {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            fontWeight: 600,
            letterSpacing: '0.01rem',
        },
        h3: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
        },
        h4: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
        },
        body1: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 400,
            letterSpacing: '0.01rem',
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '3px',
                    height: '1px',
                    borderRadius: '2rem',
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#111318',
            },
            colorDefault: {
                backgroundColor: '#ffffff',
            },
        },
    },
});

export default theme;
