// @flow
import './polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import Loader from './jsx/Loader';

import Root from './jsx/Root';
import { QueryProvider } from 'query';
import theme from './theme';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './app.css';

export const history = createBrowserHistory();
Bugsnag.start({
    apiKey: 'e760a1ee6efda221fbce076deed73fd4',
    plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = () =>  <ErrorBoundary>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <QueryProvider>
                <React.Suspense fallback={<Loader />}>
                    <I18nextProvider i18n={i18n}>
                        <Root history={history} />
                    </I18nextProvider>
                </React.Suspense>
            </QueryProvider>
        </ThemeProvider>
    </ErrorBoundary>

ReactDOM.render(<App />, document.getElementById('root'));
