//@flow

import * as React from 'react';
import { Box, Button, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ClientContext from './ClientContext';
import { useTrackTask } from './hooks/useOrderTrack';
import { history } from '../index';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { formatSlotTime } from './Utils';
import HelplineText from './HelplineText';
import Loader from './Loader';
import RoutesEnum from './RoutesEnum';
import OrderDetailsStep from './OrderDetailsStep';
import useStyles from './OrderDetailsStyles';
import { getIncorrectTrackingId } from './LandingPage';
import ServiceUnavailable from './ServiceUnavailable';

const OrderDetails = () => {
    const { t } = useTranslation();
    const { clientId, clientTheme } = React.useContext(ClientContext);
    const location = useLocation();
    let { trackingId } = queryString.parse(location.search);
    const trackingIds = trackingId
        .split(',')
        .map((splitOrder) => splitOrder.trim())
        .filter((splitOrder, index, ref) => {
            return splitOrder && ref.indexOf(splitOrder) === index;
        });

    let trackOrderQuery = null;
    const { errorMessageKey, errorMessageParams } = getIncorrectTrackingId(
        trackingIds,
        1,
    );

    if (!errorMessageKey) {
        trackOrderQuery = useTrackTask({
            clientId,
            searchIds: trackingIds.join(','),
        });
    }

    let orderDetail = null;
    if (trackOrderQuery?.data?.length > 0) orderDetail = trackOrderQuery.data;
    else if (!errorMessageKey) {
        orderDetail = trackingIds.map((id) => ({
            clientId: null,
            etd: null,
            history: [
                {
                    statusUpdates: [
                        {
                            orderStatus: 'ACCEPTED',
                            orderStatusCode: 100,
                            orderSubStatus: null,
                            orderSubStatusCode: null,
                            timeStamp: null,
                        },
                    ],
                },
            ],
            id: id,
            orderStatus: 'ACCEPTED',
            orderStatusCode: 100,
            orderSubStatus: null,
            orderSubStatusCode: null,
            slotTime: { start: null, end: null },
            sourceOrderId: id,
            timeStamp: null,
        }));
    }

    let orderStatusData = null;

    if (orderDetail) {
        orderStatusData = orderDetail
            .reduce((obj, item) => {
                const status = item.history[0].statusUpdates;
                return [...obj, ...status];
            }, [])
            .sort((a, b) => {
                if (a.timeStamp && b.timeStamp)
                    return (
                        new Date(b.timeStamp).valueOf() -
                        new Date(a.timeStamp).valueOf()
                    );
                else return 1;
            });
    }

    let filteredOrderStatus = [];

    if (orderStatusData) {
        orderStatusData = orderStatusData.reverse();
        filteredOrderStatus = orderStatusData.reduce((obj, item) => {
            const lastElement = obj[obj.length - 1];
            if (lastElement) {
                const prevStatus = t(
                    `orderStatus.${
                        lastElement.orderStatusCode === 107
                            ? lastElement.orderSubStatusCode
                            : lastElement.orderStatusCode
                    }`,
                );
                const currStatus = t(
                    `orderStatus.${
                        item.orderStatusCode === 107
                            ? item.orderSubStatusCode
                            : item.orderStatusCode
                    }`,
                );

                if (prevStatus === currStatus) return obj;
            }

            return [...obj, item];
        }, []);
        filteredOrderStatus = filteredOrderStatus.reverse();
    }

    const latestOrderDetail = orderDetail?.[0];
    const { sourceOrderId, orderStatusCode, orderSubStatusCode, slotTime } =
        latestOrderDetail || {};

    const classes = useStyles(clientTheme, orderStatusCode);
    const StyledButton = withStyles({
        root: {
            color: clientTheme.button.color,
            borderRadius: 0,
            border: `1px solid ${clientTheme.button.color}`,
            backgroundColor: 'white',
            fontSize: clientTheme.button.fontSize,
            fontWeight: clientTheme.button.fontWeight,
            width: '100%',
            maxWidth: clientTheme.button.width,
            height: clientTheme.button.height,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
    })((props) => <Button {...props} />);

    const trackNewOrderButton = (
        <StyledButton
            variant="outlined"
            onClick={() => history.push(RoutesEnum.LandingPage)}
        >
            {t('trackNewOrders')}
        </StyledButton>
    );

    if (trackOrderQuery?.isFetching) {
        return <Loader />;
    }

    if (
        trackOrderQuery?.isError &&
        trackOrderQuery?.error?.message === 'Network Error'
    ) {
        return <ServiceUnavailable />;
    }

    if (
        errorMessageKey ||
        orderStatusData?.length === 0 ||
        trackOrderQuery?.error
    ) {
        return (
            <>
                <Box
                    alignItems="center"
                    display="flex"
                    className={classes.notFound}
                    p="10px"
                    mb="25px"
                >
                    <img src="/static/error.svg" />
                    <Box ml="8px">
                        {errorMessageKey
                            ? t(errorMessageKey, errorMessageParams)
                            : t('orderNotFound', { orders: trackingId })}
                    </Box>
                </Box>
                <Box pb="16px">
                    <HelplineText />
                </Box>
            </>
        );
    }

    return (
        <>
            <Box className={classes.wrapper}>
                <Box
                    pb="8px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center">
                        <Box
                            className={classes.backButton}
                            display="flex"
                            alignItems="center"
                            onClick={() =>
                                history.push({
                                    pathname: RoutesEnum.Summary,
                                    search: queryString.stringify({
                                        trackingId,
                                    }),
                                })
                            }
                        >
                            <img
                                src="/static/chevron-left-small.svg"
                                className={classes.backButtonIcon}
                            />
                        </Box>
                        <Box className={classes.title} display="flex">
                            <Box>{t(`trackingId`)}</Box>
                            <Box ml="5px">{sourceOrderId.split('_')[0]}</Box>
                        </Box>
                    </Box>
                    <Hidden only="xs"> {trackNewOrderButton} </Hidden>
                </Box>
                <Box className={classes.orderStatusWrapper}>
                    <Box className={classes.orderStatusTitle}>
                        {t(`shipmentStatus`)}:
                    </Box>
                    <Box display="flex">
                        <Box className={classes.orderStatus}>
                            {t(
                                `orderStatus.${
                                    orderStatusCode === 107
                                        ? orderSubStatusCode
                                        : orderStatusCode
                                }`,
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.subTitleWrapper}>
                    <Box className={classes.subTitleHeader}>
                        {t('estimatedDelivery')}:
                    </Box>
                    <Box className={classes.subTitleData}>
                        {slotTime
                            ? formatSlotTime(slotTime.start, slotTime.end) ||
                              '-'
                            : '-'}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.timelineWrapper}>
                {filteredOrderStatus.map((status, i) => {
                    return (
                        <OrderDetailsStep
                            key={i}
                            status={status}
                            isLatest={i === 0}
                        />
                    );
                })}
            </Box>
            <Hidden smUp>
                <Box display="flex" justifyContent="center" pb="15px">
                    {trackNewOrderButton}
                </Box>
            </Hidden>
            <Box pb="16px">
                <HelplineText />
            </Box>
        </>
    );
};

export default OrderDetails;
