import StatusColorEnum from './StatusColorEnum';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (clientTheme, orderStatus) => {
    const styles = makeStyles((theme) => {
        return {
            wrapper: {
                margin: '0 0 15px 0',
                border: `1px solid ${clientTheme.borderTheme[300]}`,
                backgroundColor: 'white',
                borderRadius: '4px'
            },
            backButton:{
                cursor: 'pointer'
            },
            title: {
                fontWeight: 600,
            },
            subTitleHeader: {
                fontSize: '14px',
                color: '#111318',
            },
            timelineWrapper: {
                margin: '15px 0',
                border: `1px solid ${clientTheme.borderTheme[300]}`,
                backgroundColor: 'white',
                borderRadius: '4px',
            },
            orderStatusTitle:{
                fontSize: '14px',
            },
            orderStatus: {
                backgroundColor: StatusColorEnum[orderStatus],
                margin: "4px 0",
                padding: "4px 6px",
                borderRadius: "2px"
            },
            notFound: {
                backgroundColor: '#FBDCDA',
                fontSize: '14px',
                fontWeight: 600,
                borderRadius: '4px',
                marginTop: "12px",
            },
            [theme.breakpoints.down('xs')]: {
                wrapper: {
                    padding: '12px',
                },
                backButtonIcon:{
                    width: '18px'
                },
                title:{
                    fontSize: '14px',
                    lineHeight: '20px',
                    marginLeft: "6px",
                },
                subTitleHeader:{
                    paddingBottom: '4px',
                },
                subTitleData:{
                    fontSize: '12px',
                },
                timelineWrapper: {
                    padding: '12px',
                },
                orderStatusTitle:{
                    margin: "7px 0 0 25px",
                    color: '#111318',
                    paddingBottom: '4px',
                },
                orderStatus: {
                    fontSize: "12px",
                    marginLeft: "25px",
                },
                subTitleWrapper:{
                    margin: '6px 0 0 25px'
                }
            },
            [theme.breakpoints.up('sm')]: {
                wrapper: {
                    padding: '32px',
                },
                title: {
                    fontSize: '20px',
                    lineHeight: '25px',
                    marginLeft: "12px",
                },
                subTitleHeader: {
                    fontWeight: 400,
                },
                subTitleData: {
                    fontSize: '13px',
                    marginLeft: '8px',
                },
                timelineWrapper: {
                    padding: '32px',
                },
                orderStatusWrapper:{
                    display: "flex"
                },
                orderStatusTitle:{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 0 36px",
                },
                orderStatus: {
                    marginLeft: "10px",
                },
                subTitleWrapper:{
                    margin: '12px 0 0 36px',
                    display: "flex"
                }
            },
        };
    });
    return styles();
};

export default useStyles;
