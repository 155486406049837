const fastretailing = {
    showTicker: false,
    brand: {
        logoUrl: '/static/fast-retailing-uniqlo-pre-logo.svg',
        logoImgAlt: 'ユニクロ｜UNIQLO',
        websiteUrl: 'https://www.uniqlo.com/jp/ja/',
        websiteLogo: '/static/uniqlo-favicon.ico',
        copyRights: 'Copyright © UNIQLO Co., Ltd. All rights reserved.',
    },
    primaryColor: '#EE1C25',
    backgroundColor: {
        100: '#F2F3F480',
        200: '#F4F4F4',
        300: '#F3F4F6',
        400: '#E7E9EE',
    },
    borderTheme: {
        100: '#dadada',
        200: '#767676',
        300: '#F3F4F6',
        focus: '#005DB5',
    },
    button: {
        color: '#1B1B1B',
        fontSize: '16px',
        fontWeight: 300,
        width: '364px',
        height: '52px',
    },
    helpline: {
        timings: (language) =>
            `(${new Date('1/1/2021 9:00').toLocaleString(language, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })} - ${new Date('1/1/2021 17:00').toLocaleString(language, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })})`,
        phoneNumber: '0120-201-620',
    },
};

const fastretailingUniqloGu = {
    ...fastretailing,
    brand: {
        ...fastretailing.brand,
        list: ['frUniqlo', 'frGu'],
        frUniqlologoUrl: '/static/fast-retailing-uniqlo-logo.svg',
        frGulogoUrl: '/static/fast-retailing-gu-logo.svg',
        frUniqlologoImgAlt: 'ユニクロ｜UNIQLO',
        frGulogoImgAlt: 'GU',
        frUniqlowebsiteUrl: 'https://www.uniqlo.com',
        frGuwebsiteUrl: 'https://www.gu-global.com',
        frUniqlocopyRights: 'Copyright © UNIQLO Co., Ltd. All rights reserved.',
        frGucopyRights: 'Copyright © GU Co., Ltd. All rights reserved.',
    },
};

const defaultSetting = {
    brand: {
        logoUrl: '/static/locus-logo.svg',
        logoImgAlt: 'LOCUS',
        websiteUrl: 'https://locus.sh/',
        websiteLogo: '/static/locus-favicon.ico',
        copyRights: 'Locus © 2021 Mara Labs, Inc. All rights reserved',
    },
    backgroundColor: {
        100: '#F2F3F480',
        200: '#F4F4F4',
        300: '#F3F4F6',
        400: '#E7E9EE',
    },
    primaryColor: '#0072F5',
    borderTheme: {
        100: '#dadada',
        200: '#767676',
        300: '#F3F4F6',
        focus: '#005DB5',
    },
    button: {
        color: '#0072F5',
        fontSize: '12px',
        fontWeight: 700,
        width: '220px',
        height: '35px',
    },
    helpline: {
        timings: (language) =>
            `(${new Date('1/1/2021 10:00').toLocaleString(language, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })} - ${new Date('1/1/2021 18:00').toLocaleString(language, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })})`,
        phoneNumber: '096637 53844',
    },
};

export default {
    defaultSetting,
    fastretailing,
    fastretailingUniqloGu,
    'fr-e2e': fastretailingUniqloGu,
    'fr-tky': fastretailingUniqloGu,
};
