import { makeStyles } from '@material-ui/core/styles';

const useStyles = (clientTheme) => {
    const style = makeStyles((theme) => {
        const headerHeight = '65px';
        const tickerHeight = `${clientTheme?.showTicker ? '36px' : '0px'}`;
        const navigationHeight = '32px';
        const bottomBarHeight = '53px';
        return {
            bodyWrap: {
                backgroundColor: 'white',
            },
            copyRightsx: {
                paddingTop: '4px',
            },
            headerWrap: {
                height: headerHeight,
                borderBottom: `1px solid ${clientTheme.borderTheme[100]}`,
            },
            header: {
                maxWidth: '1200px',
                margin: 'auto',
                padding: '0 20px',
            },
            ticker: {
                backgroundColor: '#EE0000',
                padding: '8px 16px',
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0px',
                textAlign: 'center',
                color: '#ffffff',
            },
            navigationWrap: {
                width: '100%',
                height: navigationHeight,
                backgroundColor: clientTheme.backgroundColor[300],
            },
            navigation: {
                maxWidth: '1200px',
                margin: 'auto',
                padding: '0 20px',
            },
            trackerWrapper: {
                width: '100%',
                height: `calc(100vh - ${headerHeight} - ${bottomBarHeight} - ${navigationHeight} - ${tickerHeight})`,
                backgroundColor: clientTheme.backgroundColor[100],
                overflow: 'auto',
            },
            tracker: {
                maxWidth: '1200px',
                margin: 'auto',
                height: '100%',
            },
            bottomBarWrap: {
                borderTop: `1px solid ${clientTheme.borderTheme[100]}`,
                height: bottomBarHeight,
                backgroundColor: clientTheme.backgroundColor[200],
            },
            bottomBar: {
                maxWidth: '1200px',
                margin: 'auto',
                height: '100%',
                padding: '0 20px',
                fontWeight: 400,
                color: '#6A6A6A',
            },
            helpAndSupport: {
                fontWeight: 600,
                textDecoration: 'underline',
                color: clientTheme.primaryColor,
                paddingLeft: '3px',
            },
            needAssistance: {
                fontWeight: 400,
                paddingRight: '3px',
            },
            [theme.breakpoints.down('xs')]: {
                tracker: {
                    padding: '12px 20px 0 20px',
                },
                bottomBar: {
                    fontSize: '12px',
                },
            },
            [theme.breakpoints.up('sm')]: {
                tracker: {
                    padding: '32px 20px 0 20px',
                },
                bottomBar: {
                    fontSize: '13px',
                },
            },
        };
    });
    return style();
};

export default useStyles;
