// @flow
import { useQuery, type QueryResult } from 'react-query';
import { instance } from 'middleware/axios';

type Params = { clientId: string, searchIds: string };

export function useTrackTask({
    clientId,
    searchIds
}: Params): QueryResult<*> {
    return useQuery({
        queryKey: ['client', clientId, searchIds],
        queryFn: () => {
            return searchIds?.length === 0 ? null : instance
                .get(`client/${clientId}/track-task`, {
                    params: { searchIds },
                })
                .then((response) => response.data?.data?.orders);
        },
        refetchOnWindowFocus: false,
    });
}