//@flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import i18n, { supportedLngs } from '../i18n';
import { makeStyles } from '@material-ui/core/styles';
import ClientContext from './ClientContext';

const useStyles = (clientTheme) => {
    const style = makeStyles((theme) => {
        return {
            languageToggleContainer: {
                cursor: 'pointer',
                width: '60px',
            },
            languageDropdownContainer: {
                zIndex: 100,
                position: 'absolute',
                right: 0,
                borderBottom: `1px solid ${clientTheme.borderTheme[100]}`,
                backgroundColor: '#FFFFFF',
                marginTop: '10px',
                padding: '12px',
            },
            menuItemTitle: {
                padding: '12px',
                fontSize: '16px',
                fontWeight: 'Bold',
            },
            menuItemlabel: {
                padding: '12px',
                fontSize: '14px',
                cursor: 'pointer',
            },
            menuItemlabelSelected: {
                padding: '12px',
                fontSize: '14px',
                cursor: 'pointer',
                color: '#378694',
            },
            flag: {
                width: '12px',
                marginRight: '8px',
            },
            logo: {
                height: '40px',
            },
            logo1: {
                height: '40px',
            },
            logox: {
                height: '40px',
                marginLeft: '10px',
            },
            [theme.breakpoints.down('xs')]: {
                languageDropdownContainer: {
                    width: '100%',
                    borderTop: `1px solid ${clientTheme.borderTheme[100]}`,
                },
            },
            [theme.breakpoints.up('sm')]: {
                languageDropdownWrapper: {
                    position: 'relative',
                },
                languageDropdownContainer: {
                    width: '300px',
                    borderLeft: `1px solid ${clientTheme.borderTheme[100]}`,
                    borderRight: `1px solid ${clientTheme.borderTheme[100]}`,
                },
            },
        };
    });

    return style();
};

const AppHeader = () => {
    const { t } = useTranslation();
    const { clientTheme } = React.useContext(ClientContext);
    const classes = useStyles(clientTheme);
    const [showLngDropDown, setShowLngDropDown] = React.useState(false);
    const [selectedLng, setSelectedLng] = React.useState(i18n.language || 'en');

    const getLabel = (lng) => {
        return (
            <Box display="flex" alignItems="center">
                {t(`languages.${lng}`)}
            </Box>
        );
    };
    const languagesMapping = {
        en: {
            label: getLabel('en'),
            value: 'en',
        },
        ja: { label: getLabel('ja'), value: 'ja' },
    };
    const languageOptions = supportedLngs.map((lng) => languagesMapping[lng]);
    const handleLngChange = (value) => {
        setSelectedLng(value);
        i18n.changeLanguage(value).then(() => {
            document.title = t('websiteTitle');
            setShowLngDropDown(false);
        });
    };

    const getLogoComponent = () => {
        if (Array.isArray(clientTheme.brand.list)) {
            return (
                <>
                    {clientTheme.brand.list.map((name, index) => (
                        <a
                            key={index}
                            href={clientTheme.brand[`${name}websiteUrl`]}
                        >
                            <img
                                src={clientTheme.brand[`${name}logoUrl`]}
                                className={
                                    classes[`logo${index > 0 ? 'x' : '1'}`]
                                }
                                alt={clientTheme.brand[`${name}logoImgAlt`]}
                            />
                        </a>
                    ))}
                </>
            );
        } else {
            return (
                <a href={clientTheme.brand.websiteUrl}>
                    <img
                        src={clientTheme.brand.logoUrl}
                        className={classes.logo}
                        alt={clientTheme.brand.logoImgAlt}
                    />
                </a>
            );
        }
    };

    return (
        <Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box>{getLogoComponent()}</Box>
                <Box>
                    <Box
                        className={classes.languageToggleContainer}
                        onClick={() => setShowLngDropDown(!showLngDropDown)}
                    >
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="center"
                        >
                            <img
                                src={`/static/${
                                    showLngDropDown ? 'close.svg' : 'global.svg'
                                }`}
                            />
                        </Box>
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="center"
                            {...(showLngDropDown ? { pt: '5px' } : {})}
                        >
                            {showLngDropDown ? t('close') : t('language')}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {showLngDropDown && (
                <Box
                    display="flex"
                    justifyContent="right"
                    className={classes.languageDropdownWrapper}
                >
                    <Box className={classes.languageDropdownContainer}>
                        <Box className={classes.menuItemTitle}>
                            {t('language')}
                        </Box>
                        {languageOptions.map((lng, index) => (
                            <Box
                                key={index}
                                value={lng.value}
                                key={lng.value}
                                className={
                                    selectedLng === lng.value
                                        ? classes.menuItemlabelSelected
                                        : classes.menuItemlabel
                                }
                                onClick={() => handleLngChange(lng.value)}
                            >
                                {lng.label}
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AppHeader;
