//@flow
import * as React from 'react';
import ClientContext from './ClientContext';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { formatTime } from './Utils';

const OrderDetailsStep = ({ isLatest, status }) => {
    const { clientTheme } = React.useContext(ClientContext);
    const useStyles = makeStyles((theme) => {
        return {
            title:{
                fontWeight: 600,
            },
            [theme.breakpoints.down('xs')]: {
                title: {
                    fontSize: '14px',
                },
                subTitleXs:{
                    fontSize: '12px',
                }
            },
            [theme.breakpoints.up('sm')]: {
                title: {
                    fontSize: '16px',
                },
                subTitle:{
                    fontSize: '14px',
                },
            },
            description: {
                color: '#8992A9',
            },
            latestStatus: {
                backgroundColor: clientTheme.primaryColor,
            },
        };
    });
    const classes = useStyles();
    const { t } = useTranslation();
    const { timeStamp, orderStatusCode, orderSubStatusCode } = status;
    return (
        <Box p="8px 0">
            <Grid container>
                <Grid item xs={1}>
                    <Box
                        display="flex"
                        alignItems="center"
                        height="100%"
                        flexDirection="column"
                    >
                        {isLatest ? (
                            <Box
                                width="24px"
                                height="24px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box
                                    width="13px"
                                    height="13px"
                                    borderRadius="50%"
                                    className={classes.latestStatus}
                                />
                            </Box>
                        ) : (
                            <img src={'/static/green-tick.svg'} />
                        )}
                        <Box
                            display="flex"
                            flex={1}
                            width="0"
                            mt="2px"
                            mr="1px"
                        >
                            <Box
                                borderLeft={`1px ${
                                    isLatest ? 'dashed' : 'solid'
                                } #8992A9`}
                            ></Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box p="4px" className={classes.title}>
                                {t(`orderStatus.${orderStatusCode === 107 ? orderSubStatusCode : orderStatusCode}`)}
                            </Box>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {timeStamp && <Box p="4px" className={classes.subTitle}>{formatTime(timeStamp)}</Box>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrderDetailsStep;
