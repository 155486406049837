//@flow

import * as React from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import ClientContext from './ClientContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (clientTheme) => {
    const styles = makeStyles((theme) => ({
        loader: {
            borderRadius: '4px',
            backgroundColor: '#EAEAEA',
        },
        barColorPrimary: {
            backgroundColor: clientTheme.primaryColor,
        },
        [theme.breakpoints.down('xs')]: {
            logo: {
                width: '180px',
                height: '64px',
            },
            logo1: {
                height: '64px',
            },
            logox: {
                height: '64px',
                marginLeft: '16px',
            },
            loader: {
                width: '200px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            logo: {
                width: '180px',
                height: '80px',
            },
            logo1: {
                height: '80px',
            },
            logox: {
                height: '80px',
                marginLeft: '20px',
            },
            loader: {
                width: '280px',
            },
        },
    }));

    return styles();
};

const Loader = () => {
    const { clientTheme } = React.useContext(ClientContext);
    const classes = useStyles(clientTheme);

    const getLogoComponent = () => {
        if (Array.isArray(clientTheme.brand.list)) {
            return (
                <>
                    {clientTheme.brand.list.map((name, index) => (
                        <img
                            key={index}
                            src={clientTheme.brand[`${name}logoUrl`]}
                            className={classes[`logo${index > 0 ? 'x' : '1'}`]}
                            alt={clientTheme.brand[`${name}logoImgAlt`]}
                        />
                    ))}
                </>
            );
        } else {
            return (
                <img
                    className={classes.logo}
                    src={clientTheme.brand.logoUrl}
                    alt={clientTheme.brand.logoImgAlt}
                />
            );
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <Box mb="100px">
                <Box display="flex" mb="15px" justifyContent="center">
                    {getLogoComponent()}
                </Box>
                <Box>
                    <LinearProgress
                        classes={{
                            root: classes.loader,
                            barColorPrimary: classes.barColorPrimary,
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Loader;
