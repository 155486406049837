//@flow

import * as React from 'react';
import ClientSettings from './ClientSettings';
import config from 'config';

const clientId = !config.manuallyEnterClientId
    ? config.clientId
    : window.prompt('Enter Client Id');

export default React.createContext({
    clientId,
    clientTheme: clientId && ClientSettings[clientId] ? ClientSettings[clientId] : ClientSettings["defaultSetting"],
});
