// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClientContext from './ClientContext';

import Routes from './Routes';

type Props = {
    history: $FixMe,
};

const Root = (props: Props): React.Node => {
    const { history } = props;
    const { t } = useTranslation();
    const { clientTheme } = React.useContext(ClientContext);
    
    React.useEffect(() => {
        document.title = t('websiteTitle');
        if(document.getElementById("fav-icon"))
            document.getElementById("fav-icon").href = clientTheme.brand.websiteLogo
    }, []);

    return <Routes history={history} />;
};

export default Root;
