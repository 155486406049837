import { makeStyles } from '@material-ui/core/styles';

const useStyles = (clientTheme,errorMessage) => {
    const styles = makeStyles(() => {
        return {
            wrapper: {
                padding: '32px',
                marginBottom: '16px',
                border: `1px solid ${clientTheme.backgroundColor[300]}`,
                backgroundColor: 'white',
            },
            title: {
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '25px',
            },
            subTitle: {
                fontSize: '14px'
            },
            formTitle: {
                fontSize: '13px',
                fontWeight: 300,
                lineHeight: '20px',
                color: '#000'
            },
            enterId: {
                width: '100%',
                maxWidth: '100%',
                padding: '10px 8px',
                fontSize: '13px',
                fontWeight: 400,
                resize: 'none',
                border: `1px solid ${errorMessage ? "red" :clientTheme.borderTheme[200]}`,
                fontFamily:
                    '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Noto Sans CJK JP", Osaka, Meiryo, メイリオ, "MS PGothic", "ＭＳ Ｐゴシック", YuGothic, "Yu Gothic", "Hiragino Sans GB", Helvetica Neue, HelveticaNeue , Helvetica, Noto Sans, Roboto,Arial, "Arial Unicode MS", sans-serif;',
                '&:focus': {
                    outlineColor: `${clientTheme.borderTheme.focus}`,
                },
            },
            errorMessage:{
                margin: "5px 0 0 0",
                fontWeight: 300,
                fontSize: '11px',
                color: 'red'
            },
            placeHolder:{
                margin: "5px 0 0 0",
                color: "#6A6A6A",
                fontWeight: 300,
                fontSize: '11px'
            }
        };
    });
    return styles();
};

export default useStyles;
