//@flow

import * as React from 'react';
import { Box } from '@material-ui/core';
import ClientContext from './ClientContext';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (clientTheme) => {
    const style = makeStyles(() => {
        return {
            helplineText: {
                fontSize: "12px",
                backgroundColor: clientTheme.backgroundColor[400],
            },
        }
    });

    return style();
};

const HelplineText = (): React.Node => {
    const {
        clientTheme,
    } = React.useContext(ClientContext);
    const { helpline } = clientTheme;

    if (!helpline) return null;
    
    const classes = useStyles(clientTheme);
    const { timings, phoneNumber } = helpline;
    const { t } = useTranslation();
    return (
        <Box
            display="flex"
            px="12px"
            py="8px"
            alignItems="center"
            className={classes.helplineText}
            borderRadius="4px"
        >
            <img src="/static/phone.svg" width="14px"/>
            <Box mx="8px">
                {t('helplineText', {
                    timings: timings(i18n.language),
                    phoneNumber
                })}
            </Box>
        </Box>
    );
};

export default HelplineText;
