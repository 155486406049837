//@flow

import * as React from 'react';
import humanizeDuration from 'humanize-duration';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../i18n';

const formatTime = (timeStamp: ?string): ?string => {
    if (!timeStamp) {
        return null;
    }
    return new Date(timeStamp).toLocaleString(i18n.language, {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
};

const formatSlotTime = (
    startTimeStamp: ?string,
    endTimeStamp: ?string,
): ?string => {
    if (!startTimeStamp || !endTimeStamp) {
        return null;
    }
    const startDate = new Date(startTimeStamp).toLocaleString(i18n.language, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
    });
    const endDate = new Date(endTimeStamp).toLocaleString(i18n.language, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
    });
    const startTime = new Date(startTimeStamp).toLocaleString(i18n.language, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
    const endTime = new Date(endTimeStamp).toLocaleString(i18n.language, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    if (startDate === endDate) return `${startDate}, ${startTime} - ${endTime}`;
    else return `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
};

const getHumanizedDiff = (timeStamp: string): string => {
    const currentDate = new Date();
    const lastUpdateDiff =
        currentDate.getTime() - new Date(timeStamp).getTime();
    return humanizeDuration(lastUpdateDiff, {
        language: i18n.language,
        largest: 1,
    });
};

export const TitleContainer = (props) => {
    const { title, children, dividerProps } = props;

    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                title: {
                    fontWeight: 400,
                    letterSpacing: '0px',
                    textAlign: 'left',
                },
                [theme.breakpoints.down('xs')]: {
                    title: {
                        fontSize: '22px',
                        lineHeight: '31px',
                    },
                },
                [theme.breakpoints.down('md')]: {
                    title: {
                        fontSize: '28px',
                        lineHeight: '39px',
                    },
                },
                [theme.breakpoints.up('md')]: {
                    title: {
                        fontSize: '32px',
                        lineHeight: '45px',
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return (
        <Box>
            <Box className={classes.title}>{title}</Box>
            {children}
            <Divider {...dividerProps} />
        </Box>
    );
};

export const Divider = (props) => {
    const { dividerWidth } = props;
    const useStyles = () => {
        const style = makeStyles(() => {
            return {
                spliter: {
                    width: '100%',
                    maxWidth: dividerWidth ? dividerWidth : '100%',
                    height: '1px',
                    backgroundColor: '#DADADA',
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return <Box {...props} className={classes.spliter}></Box>;
};

export const LinkText = (props) => {
    const { children } = props;
    const useStyles = () => {
        const style = makeStyles((theme) => {
            return {
                label: {
                    fontWeight: 400,
                    letterSpacing: '0px',
                    textAlign: 'left',
                    color: '#005DB5',
                    '&:hover': {
                        color: '#006ED7',
                    },
                    '&:focus': {
                        border: '1px solid #005DB5',
                        color: '#005DB5',
                    },
                },
                [theme.breakpoints.down('xs')]: {
                    label: {
                        fontSize: '14px',
                        lineHeight: '21px',
                    },
                },
                [theme.breakpoints.down('md')]: {
                    label: {
                        fontSize: '14px',
                        lineHeight: '21px',
                    },
                },
                [theme.breakpoints.up('md')]: {
                    label: {
                        fontSize: '15px',
                        lineHeight: '23px',
                    },
                },
            };
        });
        return style();
    };

    const classes = useStyles();

    return <Box className={classes.label}>{children}</Box>;
};

export { formatTime, formatSlotTime, getHumanizedDiff };
