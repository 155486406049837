//@flow

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../index';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import RoutesEnum from './RoutesEnum';

const AppBreadcrumbs = (): React.Node => {
    const location = useLocation();
    const { t } = useTranslation();
    let { trackingId } = queryString.parse(location.search);

    const useStyles = makeStyles(() => {
        return {
            text: {
                fontSize: '11px',
                fontWeight: 300,
                cursor: 'pointer',
                color: '#000',
                textDecoration: 'underline'
            },
            disabledText: {
                fontSize: '11px',
                fontWeight: 300,
                color: '#000',
            },
        };
    });
    const classes = useStyles();
    let breadcrumbs = [];
    if (location.pathname === RoutesEnum.LandingPage) {
        breadcrumbs = [{ label: t('orderTracker'), disabled: true }];
    }
    if (location.pathname === RoutesEnum.Summary) {
        breadcrumbs = [
            {
                label: t('orderTracker'),
                onClick: () => history.push({
                    pathname: RoutesEnum.LandingPage,
                    search: queryString.stringify({ trackingId }),
                }),
            },
            { label: `/`, disabled: true },
            { label: `${t('orderList')}`, disabled: true },
        ];
    }
    if (location.pathname === RoutesEnum.Details) {
        breadcrumbs = [
            {
                label: t('orderTracker'),
                onClick: () => history.push({
                    pathname: RoutesEnum.LandingPage,
                    search: queryString.stringify({ trackingId }),
                }),
            },
            { label: `/`, disabled: true },
            {
                label: `${t('orderList')}`,
                onClick: () => history.push({
                    pathname: RoutesEnum.Summary,
                    search: queryString.stringify({ trackingId }),
                })
            },
            { label: `/`, disabled: true },
            { label: `${t('trackingDetails')}`, disabled: true },
        ];
    }

    return breadcrumbs.map((breadcrumb,index) => {
        return (
            <Box
                key={index}
                className={
                    breadcrumb.disabled ? classes.disabledText : classes.text
                }
                onClick={breadcrumb.onClick}
                pr="4px"
            >
                {breadcrumb.label}
            </Box>
        );
    });
};

export default AppBreadcrumbs;
